import request from '@/utils/request'


//获取openid
export function getOpenId(data) {
    return request({
        url: '/api/getOpenId/' + data.code,
        method: 'GET',
    })
}

//获取验证码
export function getCode(data) {
    return request({
        url: '/api/getCode',
        method: 'POST',
        data
    })
}

//查下是否注册
export function queryIsLogin(data) {
    return request({
        url: '/api/queryIsLogin/' + data.openId,
        method: 'GET',
    })
}

//获取跳转地址
export function getUrl(data) {
    return request({
        url: '/api/getUrl',
        method: 'POST',
        data,
    })
}
//获取税务对接列表
export function getReportData(data) {
    return request({
        url: '/api/getReportData',
        method: 'POST',
        data,
    })
}
//pdf下载
export function getPdf(data) {
    return request({
        url: '/api/getPdf',
        method: 'POST',
        data,
    })
}

//纳税识别号查询
export function getsearchCompany(data) {
    return request({
        url: '/api/blur_basic_detail',
        method: 'POST',
        data,
    })
}

//获取用户订单数据
export function getOrders(data) {
    return request({
        url: '/api/getOrders/' + data.openId,
        method: 'GET'
    })
}

//获取最新创建的一个订单号
export function getLastOrderNo(openId) {
    return request({
        url: '/api/getLastOrder/' + openId,
        method: 'GET'
    })
}


export function createOrder(data) {
    return request({
        url: '/api/createOrder/',
        method: 'POST',
        data,

    })
}



export function getQuotoNoticeTaxNo(data) {
    return request({
        url: '/api/getQuotoNoticeTaxNo/' + data,
        method: 'GET',
    })
}


export function getUniqueRandomStrings() {
    return request({
        url: '/api/getUniqueRandomStrings/',
        method: 'GET',
    })
}

export function createUniqueRandomStrings() {
    return request({
        url: '/api/createUniqueRandomStrings/',
        method: 'GET',
    })
}




