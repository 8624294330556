<template>
    <footer class="bottom-navigation">
      <div class="nav-item" v-for="item in navItems" :key="item.name" @click="navigate(item.route)">
        <img :src="item.route === activeRoute ? item.selectedIcon : item.icon" :alt="item.name" class="icon">
        <div class="label">{{ item.name }}</div>
      </div>
    </footer>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  
//   const navItems = ref([
//     { name: '中经授权', icon: require('@/assets/zjsl.png'), route: '/financialHome' },
//     { name: '风险监测', icon: require('@/assets/jcs.png'), route: '/riskmonitoring' },
//     { name: '个人中心', icon: require('@/assets/grzx.png'), route: '/personalcenter' }
// ]);

const navItems = ref([
  { name: '中经税联', icon: require('@/assets/zjsl.png'), selectedIcon: require('@/assets/zjsl-selected.png'), route: '/financialHome' },
  { name: '风险监控', icon: require('@/assets/jcs.png'), selectedIcon: require('@/assets/jcs-selected.png'), route: '/riskmonitoring' },
  { name: '个人中心', icon: require('@/assets/grzx.png'), selectedIcon: require('@/assets/grzx-selected.png'), route: '/personalcenter' }
]);

const router = useRouter();
const route = useRoute();
const activeRoute = ref(route.path);
  
const navigate = (route) => {
  activeRoute.value = route; // 更新当前激活的路由
  router.push(route);
};
  </script>
  
  <style scoped>
  .bottom-navigation {
    min-height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0; /* 确保导航栏横跨整个屏幕宽度 */
    display: flex; /* 使用flex布局 */
    justify-content: space-around; /* 项目之间平均分布空间 */
    align-items: center; /* 在垂直方向上居中对齐 */
    background-color: #fff; /* 或你的导航栏背景色 */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* 可选的阴影效果 */
    z-index: 1000; /* 确保导航栏在其他元素上方 */
  }
  
  .bottom-navigation .nav-item {
    /* 导航项的样式，保持水平排列 */
    display: flex;
    flex-direction: column; /* 使图标和文本垂直排列 */
    align-items: center; /* 在水平方向上居中对齐 */
  }
  
  
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .icon {
    width: 24px; /* 或根据实际情况调整 */
    height: 24px; /* 或根据实际情况调整 */
  }
  
  .label {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
  }
  </style>
  