import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Layout from '@/layout/Layout.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/list',
        name: 'List',
        component: Layout,
        redirect: 'financialList',
        children: [
            {
                path: '/financialList',
                name: 'FinancialList',
                component: () => import('../views/list/financialList.vue'),
            },
        ]
    },
    {
        path: '/lead',
        name: 'Lead',
        component: Layout,
        redirect: 'financialLead',
        children: [
            {
                path: '/financialLead',
                name: 'FinancialLead',
                component: () => import('../views/list/financialLead.vue'),
            },
        ]
    },
    {
        path: '/home',
        name: 'Home',
        component: Layout,
        redirect: 'financialHome',
        children: [
            {
                path: '/financialHome',
                name: 'FinancialHome',
                component: () => import('../views/list/financialHome.vue'),
            },
        ]
    },
    {
        path: '/search',
        name: 'Search',
        component: Layout,
        redirect: 'financialSearch',
        children: [
            {
                path: '/financialSearch',
                name: 'FinancialSearch',
                component: () => import('../views/list/financialSearch.vue'),
            },
        ]
    },
    {
        path: '/bill',
        name: 'Bill',
        component: Layout,
        redirect: 'financialBill',
        children: [
            {
                path: '/financialBill',
                name: 'FinancialBill',
                component: () => import('../views/list/financialBill.vue'),
            },
        ]
    },
    {
        path: '/monitor',
        name: 'Monitor',
        component: Layout,
        redirect: 'riskmonitoring',
        children: [
            {
                path: '/riskmonitoring',
                name: 'RiskMonitoring',
                component: () => import('../views/list/riskmonitoring.vue'),
            },
        ]
    },
    {
        path: '/center',
        name: 'Center',
        component: Layout,
        redirect: 'riskmonitoring',
        children: [
            {
                path: '/personalcenter',
                name: 'PersonalCenter',
                component: () => import('../views/list/personalcenter.vue'),
            },
        ]
    },
   
    {
        path: '/authcode',
        name: 'AuthCode',
        component: Layout,
        redirect: 'report',
        children: [
            {
                path: '/authCode',
                name: 'authCode',
                component: () => import('../views/list/authCode.vue'),
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
