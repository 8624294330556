<template>
    <div class="wrapper">
		<router-view></router-view>
    </div>
</template>

<script>
</script>

<style>
</style>