import axios from 'axios'

const service = axios.create({
    baseURL: "https://h5.ectu.com.cn/wfq",
    timeout: 60000,
});

service.interceptors.request.use(
    async (config) => {
        return config;
    },
    (error) => {
        console.log(error);
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        if (res.code == "200") {
            return Promise.resolve(res);
        } else if (res.code == "0000") {
            return Promise.resolve(res);
        }
    },
    (error) => {
        Message({
            message: error.msg || "请求失败，请重试",
            type: "error",
            duration: 2 * 1000,
        });
        return Promise.reject(error);
    }
);

export default service;