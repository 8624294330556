<template>
    <div class="container">
        <div class="beijing">
            <div class="item">
                <div class="item-title">中经税联</div>
                <div class="item-title1">智慧税务风险自检，助企业护航</div>
            </div>
        </div>
        <div class="content">
            <div class="item">
                <div class="item-label">姓名</div>
                <div class="item-input">
                    <van-field v-model="formObj.accountName" placeholder="请输入姓名" />
                </div>
            </div>
            <div class="item">
                <div class="item-label">公司名称</div>
                <div class="item-input">
                    <van-field v-model="formObj.companyName" placeholder="请输入公司名称" clearable @clear="del" @update:model-value="searchCompany" />
                </div>
            </div>
            <div class="item">
                <div class="item-label">纳税人识别号</div>
                <div class="item-input">
                    <van-field v-model="formObj.taxpayerId" placeholder="请输入纳税人识别号" disabled />
                </div>
            </div>
            <div class="item">
                <div class="item-label">手机号码</div>
                <div class="item-input">
                    <van-field v-model="formObj.mobile" placeholder="请输入手机号码" maxlength="11"/>
                </div>
            </div>
            <div class="item">
                <div class="item-label">验证码校验</div>
                <div class="item-input1">
                    <div class="item-field">
                        <van-field v-model="formObj.code" placeholder="请输入短信验证码" maxlength=6 @blur="setInput"></van-field>
                    </div>
                    <div class="item-btn1" v-if="isShow == false" @click="getCodeOne">获取验证码</div>
                    <div class="item-btn1" v-else>{{ codeNum }}s</div>
                </div>
            </div>
            <div class="item">
                <div class="item-btn" @click="onSubmit" :disabled="submitting">立即登录</div>
            </div>
            <div class="company-search" v-if="isShow1">
                <div v-for="item in dataList" :key="item.no">
                    <div class="item" @click="selectCompany(item)">
                        <div class="item-name">{{ item.name }}</div>
                        <div class="item-id">{{ item.credit_code }}</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {
    getOpenId,
    queryIsLogin,
    getUrl,
    getCode,
    getsearchCompany,
} from "../api/common.js";
import { Toast } from "vant";
// @ is an alias to /src
export default {
    name: "HomeView",
    data() {
        return {
            isShow: false,
            isShow1: false,
            codeNum: 60,
            openId: null,
            submitting:false,
            name: null,
            type:0,
            formObj: {
                accountName: null,
                companyName: null,
                mobile: null,
                code: null,
                taxpayerId: null,
            },
            dataList: [],
        };
    },
    watch: {
        // "formObj.companyName"(val) {
        //     if (
        //         this.formObj.taxpayerId == "" ||
        //         this.formObj.companyName.length > this.name.length ||
        //         this.formObj.companyName.length < this.name.length
        //     ) {
        //         this.searchCompany(val);
        //     }
        // },
    },
    created() {
        let type = this.$route.query.type;
        if (type == 0) {
            document.title = "企业注册";
        } else if (type == 1) {
            document.title = "企业财政自查";
        } else if (type == 2) {
            document.title = "企业参谋自查";
        } else if (type == 3) {
            document.title = "供应链自查";
        } else if (type == 4) {
            document.title = "企业尽调自查";
        } else if (type == 5) {
            document.title = "投资尽调自查";
        } else if (type == 6) {
            document.title = "企业融资自查";
        }
        else{
            document.title = "企业注册";
        }
        this.type = parseInt(type)+1
        localStorage.setItem("reporttype", parseInt(type)+1);
        // localStorage.setItem("skMpOpenId", "oKgl1wEUllHXU2EYXL16ywFeMcOE");
        let mpOpenId = localStorage.getItem("skMpOpenId");
        // let mpOpenId = "oKgl1wEUllHXU2EYXL16ywFeMcOE";
        // this.selectQuery(mpOpenId);
        if (!mpOpenId || mpOpenId == undefined || mpOpenId == "undefined") {
            this.$router.push("./home");
        } else {
            // this.selectQuery(mpOpenId);
        }
    },
    methods: {
        // getWxCode() {
        //     let wxcode = this.$route.query.code;
        //     if (!wxcode) {
        //         let redirect_uri = "https://hynix.heefish.cn/financial"; //hynix in

        //         let requestURL =
        //             "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9cee80d4b3b98945&redirect_uri=" +
        //             encodeURI(redirect_uri) +
        //             "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
        //         location.href = requestURL;
        //     } else {
        //         getOpenId({ code: wxcode }).then((res) => {
        //             if (res.code == "200") {
        //                 localStorage.setItem("skMpOpenId", res.data.openid);
        //                 this.openId = res.data.openid;
        //                 this.selectQuery(res.data.openid);
        //             }
        //         });
        //     }
        // },
        getCodeOne() {
            let phone = /^1[3456789]\d{9}$/;
            if (!this.formObj.mobile) {
                Toast("请输入您的手机号码");
                return false;
            }
            if (phone.test(this.formObj.mobile) == false) {
                Toast("请输入正确的手机号码");
                return false;
            }
            let p = { phone: this.formObj.mobile };
            getCode(p).then((res) => {
                Toast("验证码已发送");
            });
            this.isShow = true;
            let timeId = setInterval(() => {
                this.codeNum = this.codeNum - 1;
                if (this.codeNum == 0) {
                    this.codeNum = 60;
                    clearInterval(timeId);
                    this.isShow = false;
                }
            }, 1000);
        },
        async onSubmit() {
            if (this.submitting) return;
            this.submitting = true;

            try{
                if (!this.formObj.accountName) {
                Toast("请输入姓名");
                return false;
                }
                if (!this.formObj.companyName) {
                    Toast("请输入公司名称");
                    return false;
                }
                if (!this.formObj.taxpayerId) {
                    Toast("请输入税号");
                    return false;
                }
                if (!this.formObj.code) {
                    Toast("请输入短信验证吗");
                    return false;
                }
                localStorage.setItem("companyname", this.formObj.companyName);
                let p = this.formObj;
                p.openId = localStorage.getItem("skMpOpenId");
                p.reportType = this.type;
                await this.getAddress(p);
            }finally {
                this.submitting = false;
            }
        },
        // selectQuery(openid) {
        //     queryIsLogin({ openId: openid }).then((res) => {
        //         if (res.data.code == "A001") {
        //             Toast("您没有注册，请先注册！");
        //         } else {
        //             let obj = { openId: openid };
        //             this.getAddress(obj);
        //         }
        //     });
        // },
       async getAddress(e) {
            getUrl(e).then((res) => {
                localStorage.setItem("id", this.formObj.taxpayerId);
                console.log(res.data.url)
                debugger
                location.href = res.data.url;
            });
        },
        searchCompany(e) {
            if (e != "") {
                let p = { query_name: e };
                getsearchCompany(p).then((res) => {
                    let list = JSON.parse(res.data);
                    this.dataList = list.data.data;
                    if (this.dataList.length == 0) {
                        this.isShow1 = false;
                    } else {
                        this.isShow1 = true;
                    }
                });
            } else {
                this.isShow1 = false;
            }
        },
        selectCompany(obj) {
            this.isShow1 = false;
            this.formObj.companyName = obj.name;
            this.formObj.taxpayerId = obj.credit_code;
            this.name = obj.name;
        },
        del() {
            this.formObj.taxpayerId = null;
        },
        setInput() {
            setTimeout(function () {
                let scrollTop =
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (scrollTop > 0) {
                    document.documentElement.scrollTop = 0;
                    document.body.scrollTop = 0;
                }
            }, 100);
        },
    },
};
</script>

<style lang="scss" scoped>

.container {
    width: 100%;
    height: 100vh;
    background-color: #f9f5f5;
    .beijing {
        width: 100%;
        height: 20.9375rem;
        background-image: url("../assets/beijing.png");
        background-size: 100%;
        background-repeat: no-repeat;
        // z-index: -2;
        box-sizing: border-box;
        padding-top: 5rem;
        .item {
            width: 18.75rem;
            height: 5rem;
            margin: auto;
            .item-title {
                height: 3.125rem;
                line-height: 3.125rem;
                font-size: 1.25rem;
                font-weight: 600;
                text-align: center;
                color: #e8cca4;
                letter-spacing: 0.3125rem;
            }
            .item-title1 {
                height: 1.875rem;
                line-height: 1.875rem;
                font-size: 1rem;
                font-weight: 600;
                text-align: center;
                color: #e8cca4;
                letter-spacing: 0.3125rem;
            }
        }
    }
    .content {
        width: 92%;
        height: 31.25rem;
        // border: 1px solid red;
        margin: auto;
        margin-top: -6.875rem;
        border-radius: 0.625rem;
        // padding-top: 300px;
        // z-index: 2;
        background: white;
        padding-top: 1.25rem;
        .item {
            width: 90%;
            height: 3.75rem;
            margin: auto;
            margin-bottom: 0.625rem;
            .item-label {
                font-size: 0.875rem;
                color: #555;
                margin-bottom: 0.3125rem;
                font-weight: bold;
            }
            .item-input {
                // width: 95%;
                margin: auto;
                height: 1.875rem;
                line-height: 1.875rem;
            }
            .item-input1 {
                width: 100%;
                margin: auto;
                height: 2.5rem;
                margin-bottom: 0.625rem;
                display: flex;
                background: #fff;
                color: #323233;
                border-radius: 1.25rem;
                .item-field {
                    width: 65%;
                    // border: 1px solid red;
                }
                .item-btn1 {
                    width: 35%;
                    height: 1.875rem;
                    line-height: 1.875rem;
                    font-size: 0.875rem;
                    color: white;
                    text-align: center;
                    cursor: pointer;
                    font-weight: bold;
                    background-color: #2196f3;
                }
            }
            .item-btn {
                width: 100%;
                margin: auto;
                margin-top: 2.5rem;
                height: 2.5rem;
                line-height: 2.5rem;
                background: #2196f3;
                color: white;
                font-size: 1rem;
                text-align: center;
                font-weight: bold;
            }
        }
        .company-search {
            width: 90%;
            height: 12.5rem;
            margin: auto;
            margin-top: -20rem;
            background: white;
            box-shadow: 0px 0.125rem 0.5rem 0px rgba(0, 0, 0, 0.2);
            z-index: 999;
            position: relative;
            border-radius: 0.3125rem;
            padding: 0.3125rem;
            box-sizing: border-box;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 0.625rem;
                box-shadow: inset 0 0 5px rgba(123, 123, 123, 1);
                background: rgb(255, 255, 255, 0.2);
            }
            .item {
                width: 100%;
                height: 2.5rem;
                margin-bottom: 0.3125rem;
                .item-name {
                    line-height: 1.5625rem;
                    font-size: 0.875rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .item-id {
                    height: 0.9375rem;
                    line-height: 0.9375rem;
                    font-size: 0.75rem;
                }
            }
        }
    }
}

.van-field {
    height: 1.875rem;
    line-height: 1.875rem;
    border: 1px solid #999;
    padding: 0px 0.3125rem;
    z-index: 1;
}
</style>